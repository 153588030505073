import * as AppHttpKit from '@/utils/http/AppHttpKit';
/**
 * 以下是完成API接口调用的定义规范
 */
const TripCompanyApi = {
    search: (data) => AppHttpKit.postJSON(`/v1/tripCompanylist`,data),//这里必须写清楚接口的注释说明
    saveTripCompany: (data) => AppHttpKit.postJSON(`/v1/saveTripCompany`,data),
    queryTripCompanyById: (data) => AppHttpKit.postJSON(`/v1/queryTripCompanyById/`,data),
    updateTripCompany: (data) => AppHttpKit.postJSON(`/v1/updateTripCompany/`,data),
    deleteTripCompany: (data) => AppHttpKit.postJSON(`/v1/deleteTripCompany/`,data),
    batchDeleteTripCompany:(data) => AppHttpKit.postJSON(`/v1/batchDeleteTripCompany/`,data),
}
export default TripCompanyApi;
