<!--表单页面拷贝开始-->
<template>
  <div>
    <form-components @submitForm="submitForm" @cancelForm="cancelForm" :rules="rules" :formData="tripCompany"
      ref="form">
      <template v-slot:content>
        <el-form-item label="公司名称" prop="name">
          <el-col :span="8">
            <el-input v-model.trim="tripCompany.name" :disabled="tripCompany.id" placeholder="请输入公司名称" maxlength="40">
            </el-input>
          </el-col>
        </el-form-item>

        <el-form-item label="公司简称" prop="shortName">
          <el-col :span="8">
            <el-input v-model.trim="tripCompany.shortName" :disabled="tripCompany.id" maxlength="4" placeholder="请输入公司简称">
            </el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="联系人" prop="contactor">
          <el-col :span="8">
            <el-input v-model.trim="tripCompany.contactor" placeholder="请输入联系人" maxlength="15"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="联系电话" prop="mobile">
          <el-col :span="8">
            <el-input v-model.trim="tripCompany.mobile" maxlength="12" placeholder="请输入联系电话"></el-input>
          </el-col>
          <!-- <el-col :span="8">
            <span class="desc">(本手机号用于出租车管理公司登录平台)</span>
          </el-col> -->
        </el-form-item>

        <el-form-item label="经营地址" prop="distinctCode">
          <el-col :span="8">
            <!-- <el-input
              v-model="tripCompany.distinctCode"
              maxlength="11"
              placeholder="请输入手机号"
            ></el-input> -->
            <AreaComponents :defaultArea="selectArea" @change="areaChange" />
          </el-col>
        </el-form-item>

        <el-form-item label="详细地址" prop="address">
          <el-col :span="8">
            <el-input v-model.trim="tripCompany.address" placeholder="请输入详细地址" maxlength="60"></el-input>
          </el-col>
        </el-form-item>

        <!-- <el-form-item label="电子邮箱" prop="mail">
          <el-col :span="8">
            <el-input
              v-model="tripCompany.mail"
              placeholder="请输入电子邮箱"
              maxlength="60"
            ></el-input>
          </el-col>
        </el-form-item> -->
      </template>
    </form-components>
  </div>
</template>
  
  <script>
import FormComponents from "@/components/form/FormComponents";
import TripCompanyApi from "@/api/TripCompanyApi";
import { navTo, queryParam } from "@/utils/NavigatorUtils";
import { mapMutations } from "vuex";
import AreaComponents from "@/components/form/AreaComponents";
export default {
  name: "SaveTripCompanyPage",
  props: {
    msg: String,
  },
  components: {
    FormComponents,
    AreaComponents,
  },
  data() {
    return {
      tripCompany: {
        name: "",
        address: "",
        contactor: "",
        mobile: "",
        shortName: "",
        // mail: "",
        provinceCode: "",
        cityCode: "",
        distinctCode: "",
      },
      selectArea: [],
      rules: {
        name: [
          { required: true, message: "公司名称不能为空", trigger: "blur" },
          { min: 2, max: 40, message: "长度应为2-40字符", trigger: "blur" },
        ],
        shortName: [
          { required: true, message: "公司简称不能为空", trigger: "blur" },
        ],
        distinctCode: [{ required: true, message: "经营地址不能为空" }],
        address: [
          { required: true, message: "详细地址不能为空", trigger: "blur" },
          { min: 2, max: 60, message: "长度应为2-60字符", trigger: "blur" },
        ],
        contactor: [
          { required: true, message: "联系人不能为空", trigger: "blur" },
          { min: 1, max: 15, message: "长度应为2-40字符", trigger: "blur" },
        ],
        mobile: [
          { required: true, message: "联系电话不能为空", trigger: "blur" },
          { min: 7, max: 12, message: "联系电话长度应在7-12个字符", trigger: "blur" },
          // {
          //   pattern: /^1[3-9]\d{9}$/,
          //   message: "手机号格式错误",
          //   trigger: "blur",
          // },
        ],
        // mail: [
        //   { required: true, message: "电子邮箱不能为空", trigger: "blur" },
        //   { type: "email", message: "请输入正确的邮箱地址", trigger: "blur" },
        // ],
      },
    };
  },
  mounted() {
    this.tripCompany = this.$route.params;
    if (this.tripCompany.provinceCode) {
      this.selectArea = [
        this.tripCompany.provinceCode,
        this.tripCompany.cityCode,
        this.tripCompany.distinctCode,
      ];
      // this.$set(this,'selectArea',area)
      console.log(this.selectArea);
    }
  },
  methods: {
    ...mapMutations({
      close: "mutationCloseTag",
    }),
    submitForm() {
      this.tripCompany.id > 0
        ? this.updateTripCompanyById()
        : this.addTripCompany();
    },
    addTripCompany() {
      TripCompanyApi.saveTripCompany(this.tripCompany).then((resp) => {
        if (resp.code === '200') {
          this.tripCompany = {};
          this.$successMsg(resp.msg);
          this.cancelForm();
        } else {
          this.$errorMsg(resp.msg);
        }
      });
    },
    updateTripCompanyById() {
      TripCompanyApi.updateTripCompany(this.tripCompany).then((resp) => {
        if (resp.code === '200') {
          this.user = {};
          this.$successMsg(resp.msg);
          this.cancelForm();
        } else {
          this.$errorMsg(resp.msg);
        }
      });
    },
    cancelForm() {
      this.close({
        path: "/saveTripCompany",
      });
      this.$router.push("/tripCompanyList");
    },
    areaChange(obj) {
      if (obj.length == 3) {
        this.tripCompany.provinceCode = obj[0];
        this.tripCompany.cityCode = obj[1];
        // this.tripCompany.distinctCode = obj[2];
        this.$set(this.tripCompany, "distinctCode", obj[2]);
        this.selectArea = obj;
        this.$refs.form.validateField("distinctCode");
      }
    },
  },
};
</script>
  
  
  <!--表单页面拷贝结束-->
  <style scoped>
.desc {
  color: #999999;
  display: inline-block;
  margin-left: 8px;
}
</style>